<template>
    <v-container id="customerpage-view" fluid tag="section">
        <default-loader-page v-if="loading" />
        <default-ula />
        <v-row>
            <v-col align="center">
                <h1>Личный кабинет ИСУНГ</h1>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="12"> <customer-chart-container /></v-col>
        </v-row>    
    </v-container>
</template>

<script>
    import { get } from "vuex-pathify";

    export default {
        name: "CustomerpageView",
        components: {
            DefaultLoaderPage: () => import("@/components/LoaderPage.vue"),
            DefaultUla: () => import("@/components/ULA.vue"),
            CustomerChartContainer: () => import("@/components/chart/CustomerChartContainer.vue"),
        },
        computed: {
            // Здесь могут быть ваши вычисляемые свойства, если они необходимы для работы DefaultLoaderPage и DefaultUla
            // Например, если они используют данные из хранилища Vuex.
        },
        data() {
            return {
                loading: true,
                // Здесь могут быть ваши данные, если они необходимы для работы DefaultLoaderPage и DefaultUla
            };
        },
        methods: {
            // Здесь могут быть ваши методы, если они необходимы для работы DefaultLoaderPage и DefaultUla
        },
        async created() {
            // Здесь может быть логика инициализации, если она нужна только для работы DefaultLoaderPage и DefaultUla
            // Если она не нужна, то можете удалить этот блок created().
        }
    };
</script>